<template>
    <CardComponent
        :icon="cardIcon"
        :title="cardTitle"
        :link="cardLink"
        class="sum-data-display"
    >
        <template v-if="hasSelect" v-slot:cardOptions>
            <el-select
                v-model="warehouseId"
                style="width: 1.2rem;"
                placeholder="请选择"
                size="small"
                @change="warehouseChange()"
            >
                <el-option
                    v-for="item in warehouseList"
                    :key="item.warehouseId"
                    :label="item.warehouseName"
                    :value="item.warehouseId"
                >
                </el-option>
            </el-select>
        </template>
        <div v-loading="loading" class="sum-data-display-main">
            <template v-if="data && data.length > 0">
                <div v-for="(item, index) in data" :key="index">
                    <p>
                        <span v-if="isMoney" class="sum-unit">￥</span>
                        <span class="sum-value">{{ item.value }}</span>
                    </p>
                    <p class="sum-title">{{ item.title }}</p>
                </div>
            </template>
            <Empty v-else :show-img="false" />
        </div>
    </CardComponent>
</template>

<script>
import CardComponent from '../components/card'
import Empty from '@/components/empty'
import tradeSvc from 'services/trade'
import { moneyFilter } from '@/utils/index'
export default {
    name: 'CashCard',
    components: {
        CardComponent,
        Empty
    },
    props: {
        cardTitle: {
            type: String,
            default: '采购跟进'
        },
        cardIcon: {
            type: String,
            default: 'iconfont icon-caiwuyunying'
        },
        cardLink: {
            type: String,
            default: null
        },
        cardData: {
            type: Array,
            default: null
        },
        isMoney: {
            type: Boolean,
            default: true
        },
        items: {
            type: Array,
            default: null
        },
        fields: {
            type: Array,
            default: null
        },
        apiName: {
            type: String,
            default: null
        },
        hasSelect: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            data: [],
            warehouseList: [],
            warehouseId: '',
            loading: false
        }
    },
    computed: {
        hasData () {
            return this.cardData && this.cardData.length > 0
        }
    },
    created () {
        if (!this.hasSelect) {
            this.getData()
        } else {
            this.getWarehouseList()
        }
    },
    methods: {
        async getWarehouseList () {
            try {
                this.loading = true
                const { data = [] } = await tradeSvc.getWarehouseList()
                this.warehouseList = data
                this.warehouseId = this.hasSelect ? data[0].warehouseId : 0
                this.loading = false
                this.getData({
                    warehouseId: this.warehouseId
                })
            } catch (error) {
                console.log(error)
            }
        },
        warehouseChange () {
            this.getData({
                warehouseId: this.warehouseId
            })
        },
        async getData (query) {
            try {
                this.loading = true
                const { data = {} } = await tradeSvc[this.apiName](query || {
                    warehouseId: 0
                })
                this.handleData(data, this.fields)
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        },
        handleData (data, rest) {
            this.data = []
            this.items.forEach((item, index) => {
                let obj = {}
                obj.title = item
                obj.value = this.isMoney ? moneyFilter(data[rest[index]], true, 5) || 0 : data[rest[index]]
                this.data.push(obj)
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.sum-data-display {
    &-main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;

        > div {
            flex: 1;
        }

        p {
            font-weight: 500;
            // text-align: center;
        }

        .sum-unit {
            font-size: 0.18rem;
            color: #54636f;
        }

        .sum-value {
            font-size: 0.28rem;
            color: #54636f;
        }

        .sum-title {
            font-size: 0.14rem;
            color: #a7b6c3;
        }
    }
}
</style>
